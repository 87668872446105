import { addToDatalayer } from './addToDatalayer';
import { handleToast } from './handleToast';

export const handleAddToCart = () => {
    // Product listing add to cart button
    document.addEventListener('click', function (e) {
        if (e.target.matches('.js-single-add-to-cart')) {
            e.preventDefault();

            const button = e.target;

            addToCart({
                button: button,
                productId: button.dataset.product_id,
                qty: 1,
                additionalData: {
                    product_price: button.dataset.product_price,
                },
            });
        }
    });
};

async function addToCart({
    action = 'mda_add_to_cart',
    button,
    productId,
    qty,
    additionalData = {},
}) {
    const params = new URLSearchParams({
        action: action,
        product_id: productId,
        qty: qty,
        ...additionalData,
    });

    const cartQtyEl = document.querySelector('.header-right__cart-count');
    const popup = document.querySelector('.popup-add-to-cart');

    if (cartQtyEl)
        cartQtyEl.parentElement
            .querySelector('.loader')
            .classList.add('-active');

    let data = '';

    try {
        handleAjaxBeforeSend(button);

        const response = await fetch(ajax.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
        });

        data = await response.json();
        const { cart_qty, status, message } = data;

        if (status && message && status !== 'success')
            handleToast(status, message);

        if (status && status === 'success') {
            const datalyerSettings = JSON.parse(
                button.getAttribute('data-datalayer')
            );
            addToDatalayer({
                event: 'add_to_cart',
                ...datalyerSettings,
            });
            if (popup) popup.classList.add('-active');

            const buttonWrapper = button.closest('.add-to-cart-wrapper');
            if (buttonWrapper) buttonWrapper.classList.add('-in-cart');
        }

        if (cart_qty) cartQtyEl.textContent = cart_qty;
    } catch (error) {
        console.error('Error:', error);
    } finally {
        if (cartQtyEl)
            cartQtyEl.parentElement
                .querySelector('.loader')
                .classList.remove('-active');
        handleAjaxSuccess(data, button);
    }
}

function handleAjaxBeforeSend(button) {
    button.classList.add('-is-loading', '-disabled-add');
}

function handleAjaxSuccess(response, button) {
    button.classList.remove('-is-loading', '-disabled-add');

    if (response.status === 'success') {
        button.classList.add('-is-done');

        setTimeout(() => {
            button.classList.remove('-is-done');
        }, 3000);
    }
}

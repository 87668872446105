import Toastify from 'toastify-js';

export const handleWoocommerceToasts = (toasts = [], otherClass = false) => {
    let woocommerceNoticesWrapper = document.querySelector(
        '.woocommerce-notices-wrapper'
    );
    if (!woocommerceNoticesWrapper) return;
    if (otherClass)
        woocommerceNoticesWrapper = document.querySelector(
            '.woocommerce-NoticeGroup'
        );
    const clonedWoocommerceNoticesWrapper =
        woocommerceNoticesWrapper.cloneNode(true);
    const lists = woocommerceNoticesWrapper.querySelectorAll('ul');
    if (lists.length === 0 && woocommerceNoticesWrapper.textContent) {
        if (toasts.length > 0) {
            toasts.forEach(toast => {
                toast.hideToast();
            });
        }

        const message = clonedWoocommerceNoticesWrapper.querySelector(
            '.woocommerce-message'
        );
        const info =
            clonedWoocommerceNoticesWrapper.querySelector('.woocommerce-info');

        if (message) {
            toasts.push(
                Toastify({
                    node: message,
                    className: 'success',
                    stopOnFocus: true,
                    duration: 5000,
                    close: true,
                    gravity: 'bottom',
                }).showToast()
            );
        }

        if (info) {
            toasts.push(
                Toastify({
                    node: info,
                    className: 'success',
                    stopOnFocus: true,
                    duration: 5000,
                    close: true,
                    gravity: 'bottom',
                }).showToast()
            );
        }
    } else {
        lists.forEach(list => {
            const listClass = list.getAttribute('class');
            const status = listClass.substring(listClass.indexOf('-') + 1, 50);
            const listElements = list.querySelectorAll('li');

            listElements.forEach(element => {
                toasts.push(
                    Toastify({
                        node: element,
                        className: status,
                        stopOnFocus: true,
                        duration: 5000,
                        close: true,
                        gravity: 'bottom',
                    }).showToast()
                );
            });
        });
    }
};

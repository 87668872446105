import { addToDatalayer } from './addToDatalayer';
import { readCookie, setCookie } from './handleCookies';

export const handleDataLayer = () => {
    const links = document.querySelectorAll('a');
    if (links.length > 0) {
        links.forEach(link => {
            link.addEventListener('click', e => {
                if (link.hasAttribute('download')) {
                    const url = new URL(link.href);
                    const fileName = url.pathname.split('/').pop();
                    const fileExtension = fileName.includes('.')
                        ? fileName.split('.').pop()
                        : '';
                    addToDatalayer({
                        event: 'file_download',
                        file_extension: fileExtension,
                        file_name: fileName,
                        link_classes: link.className,
                        link_id: link.id,
                        link_text: link.textContent.trim(),
                        link_url: link.href,
                    });
                } else {
                    addToDatalayer({
                        event: 'click_out',
                        link_classes: link.className,
                        link_domain: new URL(link.href).hostname,
                        link_id: link.id,
                        link_url: link.href,
                        outbound:
                            new URL(link.href).hostname !==
                            window.location.hostname,
                    });
                }
            });
        });
    }

    const hasBeenOnSite = readCookie('hasBeenOnSite');

    if (!hasBeenOnSite) {
        addToDatalayer({
            event: 'first_visit',
        });
        setCookie('hasBeenOnSite', 1, 9999);
    }
};

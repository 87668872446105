export default function headerWcag() {
    const wcagPanelItems = document.querySelectorAll(".js-wcag-panel-item");
    const mobileWcagTriggerButton = document.querySelector(".header-wcag-mobile__button");
    if (wcagPanelItems.length > 0) {
        const localStorageName = 'wcagFunctions';
        const htmlElement = document.documentElement;

        wcagPanelItems.forEach(item => {
            item.addEventListener("click", () => {
                if (!htmlElement.classList.contains(`wcag-${item.getAttribute('data-wcag')}`)) {
                    addToLocalStorage(item);
                } else {
                    removeFromLocalStorage(item);
                }
            })
        })


        const getLocalStorage = () => {
            return localStorage.getItem(localStorageName);
        };

        const initLocalStorageClasses = () => {
            const localStorageArray = JSON.parse(getLocalStorage());

            if (localStorageArray && localStorageArray.length > 0) {
                localStorageArray.forEach(element => {
                    htmlElement.classList.add(`wcag-${element}`);
                });
            }
        };

        initLocalStorageClasses();

        const addToLocalStorage = item => {
            if (!getLocalStorage()) {
                localStorage.setItem(localStorageName, JSON.stringify([]));
            }
            const localStorageArray = JSON.parse(getLocalStorage());
            const wcagData = item.getAttribute("data-wcag");
            localStorageArray.push(wcagData);
            htmlElement.classList.add(`wcag-${wcagData}`);
            localStorage.setItem(
                localStorageName,
                JSON.stringify(localStorageArray)
            );
        };

        const removeFromLocalStorage = item => {
            const wcagData = item.getAttribute("data-wcag");
            const localStorageArray = JSON.parse(
                localStorage.getItem(localStorageName)
            );
            const removedElementLocalStorageArray = localStorageArray.filter(
                i => i !== wcagData
            );
            localStorage.setItem(
                localStorageName,
                JSON.stringify(removedElementLocalStorageArray)
            );
            htmlElement.classList.remove(`wcag-${wcagData}`);
        };
    }

    if (mobileWcagTriggerButton) {
        mobileWcagTriggerButton.addEventListener("click", (e) => {
            const parent = e.currentTarget.parentElement;
            if (parent.classList.contains("-active")) {
                parent.classList.remove("-active");
            } else {
                parent.classList.add("-active");
            }
        })
    }
}

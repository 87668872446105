const openHeaderMenu = headerMenu => {
    headerMenu.classList.add('-active');
};

const closeHeaderMenu = headerMenu => {
    headerMenu.classList.remove('-active');
};

const handleActiveClass = headerMenu => {
    if (window.scrollY > 30) {
        headerMenu.classList.add('-active');
    } else {
        headerMenu.classList.remove('-active');
    }
};

const setActiveSecondLevel = (secondLevels, index) => {
    secondLevels.forEach(item => item.classList.remove('-active'));
    const parent = secondLevels[index].closest('.header-megamenu__container');
    const topLastColumn = parent.querySelector(
        '.header-megamenu__last-column.-top'
    );
    if (secondLevels[index].classList.contains('-has-child')) {
        secondLevels[index].classList.add('-active');
        if (topLastColumn) topLastColumn.classList.remove('-active');
    } else {
        if (topLastColumn) topLastColumn.classList.add('-active');
    }
};

export const handleHeader = () => {
    const header = document.querySelector('.header');
    if (!header) return;
    const main = document.querySelector('main');
    const burgerOpenButton = header.querySelector(
        '.header-right__burger-wrapper'
    );
    const burgerCloseButtons = header.querySelectorAll('.header__close-burger');
    const headerMenu = header.querySelector('.header__menu-wrapper');

    burgerOpenButton.addEventListener('click', () =>
        openHeaderMenu(headerMenu)
    );

    if (main) {
        main.addEventListener('mouseover', e => {
            const activeMenuElement = header.querySelector(
                '.header-menu__item.-first.-show'
            );

            if (activeMenuElement) activeMenuElement.classList.remove('-show');
        });
    }

    burgerCloseButtons.forEach(burgerCloseButton => {
        burgerCloseButton.addEventListener('click', () => {
            const backToFirstLevel = burgerCloseButton.getAttribute(
                'data-back-to-first-level'
            );
            const backToSecondLevel = burgerCloseButton.getAttribute(
                'data-back-to-second-level'
            );
            if (backToFirstLevel) {
                const firstActiveLevelItem = header.querySelector(
                    '.header-menu__item.-first.-show-megamenu'
                );
                firstActiveLevelItem.classList.remove('-show-megamenu');
            } else if (backToSecondLevel) {
                const secondActiveLevelItem = header.querySelector(
                    '.header-megamenu__item.-second.-active'
                );
                secondActiveLevelItem.classList.remove('-active');
                const parent = secondActiveLevelItem.closest(
                    '.header-megamenu__container'
                );
                const topLastColumn = parent.querySelector(
                    '.header-megamenu__last-column.-top'
                );
                if (topLastColumn) topLastColumn.classList.add('-active');
            } else {
                closeHeaderMenu(headerMenu);
            }
        });
    });

    handleActiveClass(header);
    window.addEventListener('scroll', () => {
        handleActiveClass(header);
    });

    const megamenus = header.querySelectorAll('.header-megamenu');
    if (megamenus.length > 0) {
        megamenus.forEach(megamenu => {
            const secondLevelItems = megamenu.querySelectorAll(
                '.header-megamenu__item.-second'
            );
            if (window.innerWidth > 1099.98)
                setActiveSecondLevel(secondLevelItems, 0);
            if (secondLevelItems.length > 0) {
                secondLevelItems.forEach((secondLevelItem, index) => {
                    secondLevelItem.addEventListener('click', e => {
                        if (
                            e.target.classList.contains('header-megamenu__link')
                        ) {
                            if (
                                e.target.parentElement.classList.contains(
                                    '-has-child'
                                )
                            ) {
                                e.preventDefault();
                                setActiveSecondLevel(secondLevelItems, index);
                            }
                        }
                    });
                });
            }
        });
    }

    const firstLevelItemsWithChilds = header.querySelectorAll(
        '.header-menu__item.-first'
    );

    if (firstLevelItemsWithChilds.length > 0) {
        let activeMenuKey = -1;
        firstLevelItemsWithChilds.forEach((firstLevel, key) => {
            const arrow = firstLevel.querySelector(
                '.header-menu__item-chevron'
            );
            if (arrow)
                arrow.addEventListener('click', () =>
                    firstLevel.classList.add('-show-megamenu')
                );
            const firstItemATags = firstLevel.querySelectorAll('a');

            firstItemATags.forEach(firstItemATag => {
                firstItemATag.addEventListener('focus', e => {
                    if (
                        key !== activeMenuKey &&
                        firstLevelItemsWithChilds[activeMenuKey]
                    ) {
                        firstLevelItemsWithChilds[
                            activeMenuKey
                        ].classList.remove('-show');
                    }
                    if (firstLevel.classList.contains('-has-child')) {
                        firstLevel.classList.add('-show');
                    }
                    activeMenuKey = key;
                });

                firstItemATag.addEventListener('blur', function () {
                    // Get the currently focused (active) element
                    const focusedElement = document.activeElement;
                });
            });
        });
    }
};
